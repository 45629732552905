import axios from 'axios';
const KEY = 'AIzaSyC5rDmbAC3ELdS-vA1oykJnkyZIXLXVji8';

const youtube = axios.create({
    baseURL: 'https://www.googleapis.com/youtube/v3/',
    params: {
        key: KEY
    }
});

function translate(strings, fromLanguageCode, toLanguageCode) {
    return axios.post("https://translation.googleapis.com/language/translate/v2?key=" + KEY, {
        q: strings,
        source: fromLanguageCode,
        target: toLanguageCode,
        format: "text"
    });
}

export { youtube, translate }
