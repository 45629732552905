import React, { useState } from "react";

import AnkiExport from 'anki-apkg-export';
import { saveAs } from 'file-saver';
import Button from "@material-ui/core/Button";

import { useAuth } from "./../util/auth.js";
import { useLatestDownloadByOwner, logDownload } from "./../util/db.js"
import { getEnglishNameForLanguageCode } from "./LanguageSelect";

function DownloadAnkiButtons(props) {

    const auth = useAuth();

    const [latestDownloadTimestamp, setLatestDownloadTimestamp] = useState();

    const {
        data: downloads,
        status: downloadsStatus,
        error: downloadsError,
    } = useLatestDownloadByOwner(auth.user.uid, props.languageCode, latestDownloadTimestamp);

    const download = (items, successCallback) => {
        const name = getEnglishNameForLanguageCode(props.languageCode);
        const apkg = new AnkiExport('Lindsay Method - ' + name);

        items.forEach(i => {
            apkg.addCard(i.term, i.translation);
        });
        apkg
            .save()
            .then(zip => {
                saveAs(zip, 'output.apkg');
            })
            .then(successCallback)
            .catch(err => console.log(err.stack || err));
    }

    let latestVocabForDownload = [];
    if (!downloads && props.vocab) {
        // get them all
        latestVocabForDownload = props.vocab;
    } else if (props.vocab && downloads[0]) {
        const latestDownload = downloads[0];
        latestVocabForDownload = props.vocab.filter(item => item.createdAt > latestDownload.createdAt);
    } else {
        return <></>;        
    }

    return (
        <>
            <Button
                variant="contained"
                size="medium"
                color="primary"
                disabled={!!props.vocab}
                onClick={() => { download(props.vocab); }}
            >
                Download All
            </Button>
            <Button
                variant="contained"
                size="medium"
                color="primary"
                disabled={latestVocabForDownload.length === 0}
                onClick={() => {
                    download(latestVocabForDownload, () => {
                        logDownload(auth.user.uid, props.languageCode);
                        setLatestDownloadTimestamp(Date.now());
                    });
                }}
            >
                Download Latest
                {latestVocabForDownload.length > 0 && (" (" + latestVocabForDownload.length + ")")}
            </Button>
        </>
    )
}

export default DownloadAnkiButtons;
