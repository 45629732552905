import React, { useState } from "react";
import Section from "./Section";
import MainUnit from "./MainUnit";
import Container from "@material-ui/core/Container";
import LanguageSelect from "./LanguageSelect";
import SectionHeader from "./SectionHeader";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import DashboardItems from "./DashboardItems";
import { useRouter } from "./../util/router.js";
import { useAuth } from "./../util/auth.js";
import { makeStyles } from "@material-ui/core/styles";

const DEFAULT_LANGUAGE_CODE = "hu";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
  },
}));

function DashboardSection(props) {
  const classes = useStyles();

  const auth = useAuth();
  const router = useRouter();

  const [languageCode, setLanguageCode] = useState(DEFAULT_LANGUAGE_CODE);

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {router.query.paid && auth.user.planIsActive && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="success">
              You are now subscribed to the {auth.user.planId} plan
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>
        )}
        <Grid container={true} spacing={4}>
          <Grid item={true} xs={12} md={12}>
            <LanguageSelect defaultValue="hu" onChange={(result) => {
              setLanguageCode(result.languageCode);
            }} />
          </Grid>
          <Grid item={true} xs={12} md={12}>
            <MainUnit languageCode={languageCode} />
          </Grid>
          <Grid item={true} xs={12} md={12}>
            <DashboardItems languageCode={languageCode} />
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default DashboardSection;
