import React from 'react';
import SearchBar from "./SearchBar";
import { youtube, translate } from "../clientApi/google";
import VideoList from "./VideoList";
import VideoDetail from "./VideoDetail";
import { getNativeNameForLanguageCode } from "./LanguageSelect";

class MainUnit extends React.Component {
    state = {
        captions: null,
        videos: [],
        selectedVideo: null,
        shouldTranslateQuery: true,
    }
    handleSubmit = async (termFromSearchBar) => {
        let searchTerm = termFromSearchBar;
        if (this.state.shouldTranslateQuery) {
            let res = await translate([termFromSearchBar], "en", this.props.languageCode);
            searchTerm = res.data.data.translations[0].translatedText;
        }
        const response = await youtube.get('/search', {
            params: {
                maxResults: 15,
                part: 'snippet',
                q: searchTerm + " " + getNativeNameForLanguageCode(this.props.languageCode),
                type: 'video',
                videoCaption: 'closedCaption',
            }
        });

        let promises = response.data.items.map(result => {
            let query = youtube.get('/captions', {
                params: {
                    part: 'snippet',
                    videoId: result.id.videoId,
                }
            });
            return query;
        });

        let captionData = await Promise.all(promises);
        let haveSubtitles = new Set();
        captionData.forEach(cd => {
            let items = cd.data.items;
            if (items.find(i => i.snippet.language === this.props.languageCode) !== undefined) {
                haveSubtitles.add(items[0].snippet.videoId);
            }
        });
        let itemsWithCaptions = response.data.items.filter(result => haveSubtitles.has(result.id.videoId));
        this.setState({
            videos: itemsWithCaptions
        });
    };

    render() {
        return (
            <div style={{}}>
                <div className='search-bar ui segment'>
                    <SearchBar handleFormSubmit={this.handleSubmit} />
                    <input
                        style={{ marginRight: "5px", marginTop: "5px" }}
                        type="checkbox"
                        value={this.state.shouldTranslateQuery}
                        onChange={(state) => {
                            this.setState({
                                shouldTranslateQuery: state
                            });
                        }}
                    />
                    Translate query

                </div>
                <div className='ui grid'>
                    <div className="ui row">
                        <div className="eleven wide column">
                            {this.state.selectedVideo &&
                                (<VideoDetail
                                    captions={this.state.captions}
                                    languageCode={this.props.languageCode}
                                    videoId={this.state.selectedVideo.id.videoId}
                                />)}
                        </div>
                        <div className="five wide column">
                            <VideoList
                                handleVideoSelect={(video) => {
                                    this.setState({ selectedVideo: video })
                                }}
                                videos={this.state.videos} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MainUnit;
