import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import YouTube from 'react-youtube';
import Tooltip from "react-simple-tooltip"
import { translate } from "../clientApi/google";
import IconButton from "@material-ui/core/IconButton";
import EditVocabModal from "./EditVocabModal";
import StarIcon from "@material-ui/icons/Star";
import he from "he";

function VideoDetail(props) {
    const [currCaptionText, setCurrCaptionText] = useState("");
    const [translations, setTranslations] = useState({});
    const [currCaptionStart, setCurrCaptionStart] = useState(null);
    const [passNum, setPassNum] = useState(0);
    const [currentVideo, setCurrentVideo] = useState(null);
    const [segments, setSegments] = useState(null);
    const [creatingItem, setCreatingItem] = useState(false);
    const [currentTerm, setCurrentTerm] = useState(false);
    const [currentTranslation, setCurrentTranslation] = useState(false);

    const currCaptionStartRef = useRef(currCaptionStart);
    const currCaptionTextRef = useRef(currCaptionText);
    const passNumRef = useRef(passNum);
    const segmentsRef = useRef(segments);

    useEffect(() => {
        setSegments({});
        setPassNum(0);
        setCurrCaptionText("");
        setTranslations({});
        setCurrentVideo(null);
        async function handleVideoSelect() {
            const response = await axios.create({
                baseURL: 'https://www.youtube.com'
            }).get('/api/timedtext', {
                params: {
                    lang: props.languageCode,
                    v: props.videoId,
                }
            });
            let parser = new DOMParser();
            let xmlDoc = parser.parseFromString(response.data, "text/xml");
            let newSegments = Array.prototype.slice.call(xmlDoc.getElementsByTagName("text")).map(seg => {
                let text = seg.textContent;
                let start = Number(seg.attributes.start.value);
                let dur = Number(seg.attributes.dur.value);
                return { text, start, dur };
            });
            setSegments(() => {
                segmentsRef.current = newSegments;
                return newSegments;
            });
        }
        handleVideoSelect();
    }, [props.videoId]);

    useEffect(() => {
        async function getTranslation() {
            if (currCaptionText) {
                if (translations[currCaptionText]) {
                    return;
                }
                let tokens = [currCaptionText, ...currCaptionText.replace("\n", " ").split(' ')];
                let results = await translate(tokens, props.languageCode, "en");
                let newTranslations = {};
                for (let i = 0; i < tokens.length; i++) {
                    let translation = results.data.data.translations[i].translatedText;
                    newTranslations[tokens[i]] = translation;
                }
                setTranslations(old => ({ ...old, ...newTranslations }));
            }
        }
        getTranslation();
    }, [currCaptionText]);

    useEffect(() => {
        let timer = setInterval(() => {
            if (!currentVideo) {
                return;
            }
            if (!segmentsRef.current) {
                return;
            }
            const currTime = currentVideo.getCurrentTime();
            let caption;
            for (let i = segmentsRef.current.length - 1; i >= 0; i--) {
                let seg = segmentsRef.current[i];
                if (currTime >= seg.start && currTime < seg.start + seg.dur) {
                    caption = seg;
                    break;
                }
            }

            // possible states:
            // there was no caption before, and now there is
            if (caption && currCaptionStartRef.current === null) {
                // set the text to empty and the passNum to 0
                setCurrCaptionText(() => {
                    const decodedText = he.decode(caption.text);
                    currCaptionTextRef.current = decodedText;
                    return decodedText;
                });
                setPassNum(() => {
                    passNumRef.current = 0;
                    return 0;
                });
                setCurrCaptionStart(() => {
                    currCaptionStartRef.current = caption.start;
                    return caption.start;
                });
            }

            // caption matches the current caption
            else if (caption && currCaptionStartRef.current !== null && caption.start === currCaptionStartRef.current) {
                // no-op
            }

            // there was a caption before, and now there is not...or it's a different caption
            else if ((!caption && currCaptionStartRef.current !== null) ||
                (caption && caption.start !== currCaptionStartRef.current)) {
                // if we've already completed 4 passes, reset
                if (passNumRef.current === 2) {
                    setCurrCaptionText(() => {
                        let newText = caption ? he.decode(caption.text) : "";
                        currCaptionTextRef.current = newText;
                        return newText;
                    });
                    setCurrCaptionStart(() => {
                        const newVal = caption ? caption.start : null;
                        currCaptionStartRef.current = newVal;
                        return newVal;
                    });
                    setPassNum(() => {
                        passNumRef.current = 0;
                        return 0;
                    });
                } else {
                    // seek to the beginning of the caption and update the caption text
                    setPassNum(passNum => {
                        let newPassNum = passNum + 1;
                        passNumRef.current = newPassNum;
                        return newPassNum;
                    });
                    if (passNumRef.current === 2) {
                        //event.target.setPlaybackRate(1);
                    } else if (currentVideo.getPlaybackRate() !== 1) {
                        //event.target.setPlaybackRate(1);
                    }
                    currentVideo.seekTo(currCaptionStartRef.current);
                }
            }
        }, 100);

        return function cleanup() {
            clearInterval(timer);
        };
    }, [currentVideo]);

    let tokens = currCaptionText.replace("\n", " ").split(' ');
    let translationDiv = (
        <span>
            {
                tokens.map((token, i) => (
                    <span key={i}>
                        <Tooltip
                            arrow={2}
                            background="#000"
                            border="#000"
                            color="#fff"
                            content={(
                                <>
                                    {translations[token] ? translations[token] : ""}
                                    <IconButton
                                        edge="end"
                                        aria-label="star"
                                        onClick={() => {
                                            setCurrentTerm(token);
                                            setCurrentTranslation(translations[token]);
                                            setCreatingItem(true);
                                            currentVideo.pauseVideo();
                                        }
                                        }
                                    >
                                        <StarIcon style={{ color: "white" }} />
                                    </IconButton>
                                </>
                            )}
                            fadeDuration={0}
                            fadeEasing="linear"
                            fixed={false}
                            fontFamily="inherit"
                            fontSize="inherit"
                            offset={0}
                            padding={16}
                            placement="top"
                            radius={0}
                            zIndex={1}
                        >
                            {token}&nbsp;
                        </Tooltip>
                    </span>
                ))
            }
            <IconButton
                edge="end"
                aria-label="star"
                onClick={() => {
                    setCurrentTerm(currCaptionText);
                    setCurrentTranslation(translations[currCaptionText]);
                    setCreatingItem(true);
                    currentVideo.pauseVideo();
                }
                }   
            >
                <StarIcon />
            </IconButton>
        </span>
    );

    return (
        <div>
            {creatingItem && (
                <EditVocabModal
                    languageCode={props.languageCode}
                    term={currentTerm}
                    translation={currentTranslation}
                    onDone={() => setCreatingItem(false)}
                />)
            }

            <div className='ui embed'>
                <YouTube
                    videoId={props.videoId}
                    onStateChange={(event) => { if (event.data === -1) { setCurrentVideo(event.target); } }}
                />
            </div>
            <div className='ui segment'>
                <h3 className='ui header'>
                    {passNum >= 1 && translationDiv}
                </h3>
                <h3 className='ui header' style={{ color: "blue" }}>
                    {passNum === 2 && translations[currCaptionText]}
                </h3>
            </div>
        </div>
    );
}

export default VideoDetail;
