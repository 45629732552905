import React from 'react';

function getLanguageList() {
    return {
        'af': {
            nativeName: "Afrikaans",
            englishName: "Afrikaans"
        },
        'ak': {
            nativeName: "Tɕɥi",
            englishName: "Akan"
        },
        'ar': {
            nativeName: "العربية",
            englishName: "Arabic"
        },
        'ay-BO': {
            nativeName: "Aymar aru",
            englishName: "Aymara"
        },
        'az': {
            nativeName: "Azərbaycan dili",
            englishName: "Azerbaijani"
        },
        'be-BY': {
            nativeName: "Беларуская",
            englishName: "Belarusian"
        },
        'bg': {
            nativeName: "Български",
            englishName: "Bulgarian"
        },
        'bn': {
            nativeName: "বাংলা",
            englishName: "Bengali"
        },
        'br': {
            nativeName: "Brezhoneg",
            englishName: "Breton"
        },
        'bs-BA': {
            nativeName: "Bosanski",
            englishName: "Bosnian"
        },
        'ca': {
            nativeName: "Català",
            englishName: "Catalan"
        },
        'ck-US': {
            nativeName: "ᏣᎳᎩ (tsalagi)",
            englishName: "Cherokee"
        },
        'cs': {
            nativeName: "Čeština",
            englishName: "Czech"
        },
        'cy': {
            nativeName: "Cymraeg",
            englishName: "Welsh"
        },
        'da': {
            nativeName: "Dansk",
            englishName: "Danish"
        },
        'de': {
            nativeName: "Deutsch",
            englishName: "German"
        },
        'el': {
            nativeName: "Ελληνικά",
            englishName: "Greek"
        },
        'en': {
            nativeName: "English",
            englishName: "English"
        },
        'eo': {
            nativeName: "Esperanto",
            englishName: "Esperanto"
        },
        'es': {
            nativeName: "Español",
            englishName: "Spanish"
        },
        'et': {
            nativeName: "eesti keel",
            englishName: "Estonian"
        },
        'eu': {
            nativeName: "Euskara",
            englishName: "Basque"
        },
        'fa': {
            nativeName: "فارسی",
            englishName: "Persian"
        },
        'ff': {
            nativeName: "Fulah",
            englishName: "Fulah"
        },
        'fi': {
            nativeName: "Suomi",
            englishName: "Finnish"
        },
        'fo-FO': {
            nativeName: "Føroyskt",
            englishName: "Faroese"
        },
        'fr': {
            nativeName: "Français",
            englishName: "French"
        },
        'fy-NL': {
            nativeName: "Frysk",
            englishName: "Frisian (West)"
        },
        'ga': {
            nativeName: "Gaeilge",
            englishName: "Irish"
        },
        'gd': {
            nativeName: "Gàidhlig",
            englishName: "Gaelic"
        },
        'gl': {
            nativeName: "Galego",
            englishName: "Galician"
        },
        'gn-PY': {
            nativeName: "Avañe'ẽ",
            englishName: "Guarani"
        },
        'gu-IN': {
            nativeName: "ગુજરાતી",
            englishName: "Gujarati"
        },
        'gv': {
            nativeName: "Gaelg",
            englishName: "Manx"
        },
        'gx-GR': {
            nativeName: "Ἑλληνική ἀρχαία",
            englishName: "Classical Greek"
        },
        'he': {
            nativeName: "עברית‏",
            englishName: "Hebrew"
        },
        'hi': {
            nativeName: "हिन्दी",
            englishName: "Hindi"
        },
        'hr': {
            nativeName: "Hrvatski",
            englishName: "Croatian"
        },
        'ht': {
            nativeName: "Kreyòl",
            englishName: "Haitian Creole"
        },
        'hu': {
            nativeName: "Magyar",
            englishName: "Hungarian"
        },
        'hy-AM': {
            nativeName: "Հայերեն",
            englishName: "Armenian"
        },
        'id': {
            nativeName: "Bahasa Indonesia",
            englishName: "Indonesian"
        },
        'is': {
            nativeName: "Íslenska",
            englishName: "Icelandic"
        },
        'it': {
            nativeName: "Italiano",
            englishName: "Italian"
        },
        'ja': {
            nativeName: "日本語",
            englishName: "Japanese"
        },
        'jv-ID': {
            nativeName: "Basa Jawa",
            englishName: "Javanese"
        },
        'ka-GE': {
            nativeName: "ქართული",
            englishName: "Georgian"
        },
        'kk-KZ': {
            nativeName: "Қазақша",
            englishName: "Kazakh"
        },
        'km': {
            nativeName: "ភាសាខ្មែរ",
            englishName: "Khmer"
        },
        'kn': {
            nativeName: "ಕನ್ನಡ",
            englishName: "Kannada"
        },
        'ko': {
            nativeName: "한국어",
            englishName: "Korean"
        },
        'ku-TR': {
            nativeName: "Kurdî",
            englishName: "Kurdish"
        },
        'kw': {
            nativeName: "Kernewek",
            englishName: "Cornish"
        },
        'la': {
            nativeName: "Latin",
            englishName: "Latin"
        },
        'lb': {
            nativeName: "Lëtzebuergesch",
            englishName: "Luxembourgish"
        },
        'li-NL': {
            nativeName: "Lèmbörgs",
            englishName: "Limburgish"
        },
        'lt': {
            nativeName: "Lietuvių",
            englishName: "Lithuanian"
        },
        'lv': {
            nativeName: "Latviešu",
            englishName: "Latvian"
        },
        'mk': {
            nativeName: "Македонски",
            englishName: "Macedonian"
        },
        'ml': {
            nativeName: "മലയാളം",
            englishName: "Malayalam"
        },
        'mn-MN': {
            nativeName: "Монгол",
            englishName: "Mongolian"
        },
        'mr': {
            nativeName: "मराठी",
            englishName: "Marathi"
        },
        'ms': {
            nativeName: "Bahasa Melayu",
            englishName: "Malay"
        },
        'mt': {
            nativeName: "Malti",
            englishName: "Maltese"
        },
        'my': {
            nativeName: "ဗမာစကာ",
            englishName: "Burmese"
        },
        'no': {
            nativeName: "Norsk",
            englishName: "Norwegian"
        },
        'nb': {
            nativeName: "Norsk (bokmål)",
            englishName: "Norwegian (bokmal)"
        },
        'ne': {
            nativeName: "नेपाली",
            englishName: "Nepali"
        },
        'nl': {
            nativeName: "Nederlands",
            englishName: "Dutch"
        },
        'nn-NO': {
            nativeName: "Norsk (nynorsk)",
            englishName: "Norwegian (nynorsk)"
        },
        'oc': {
            nativeName: "Occitan",
            englishName: "Occitan"
        },
        'or-IN': {
            nativeName: "ଓଡ଼ିଆ",
            englishName: "Oriya"
        },
        'pa': {
            nativeName: "ਪੰਜਾਬੀ",
            englishName: "Punjabi"
        },
        'pl': {
            nativeName: "Polski",
            englishName: "Polish"
        },
        'ps-AF': {
            nativeName: "پښتو",
            englishName: "Pashto"
        },
        'pt': {
            nativeName: "Português",
            englishName: "Portuguese"
        },
        'qu-PE': {
            nativeName: "Qhichwa",
            englishName: "Quechua"
        },
        'rm-CH': {
            nativeName: "Rumantsch",
            englishName: "Romansh"
        },
        'ro': {
            nativeName: "Română",
            englishName: "Romanian"
        },
        'ru': {
            nativeName: "Русский",
            englishName: "Russian"
        },
        'sa-IN': {
            nativeName: "संस्कृतम्",
            englishName: "Sanskrit"
        },
        'se-NO': {
            nativeName: "Davvisámegiella",
            englishName: "Northern Sámi"
        },
        'sh': {
            nativeName: "српскохрватски",
            englishName: "Serbo-Croatian"
        },
        'si-LK': {
            nativeName: "පළාත",
            englishName: "Sinhala (Sri Lanka)"
        },
        'sk': {
            nativeName: "Slovenčina",
            englishName: "Slovak"
        },
        'sl': {
            nativeName: "Slovenščina",
            englishName: "Slovenian"
        },
        'so-SO': {
            nativeName: "Soomaaliga",
            englishName: "Somali"
        },
        'sq': {
            nativeName: "Shqip",
            englishName: "Albanian"
        },
        'sr': {
            nativeName: "Српски",
            englishName: "Serbian"
        },
        'su': {
            nativeName: "Basa Sunda",
            englishName: "Sundanese"
        },
        'sv': {
            nativeName: "Svenska",
            englishName: "Swedish"
        },
        'sw': {
            nativeName: "Kiswahili",
            englishName: "Swahili"
        },
        'ta': {
            nativeName: "தமிழ்",
            englishName: "Tamil"
        },
        'te': {
            nativeName: "తెలుగు",
            englishName: "Telugu"
        },
        'tg': {
            nativeName: "забо́ни тоҷикӣ́",
            englishName: "Tajik"
        },
        'th': {
            nativeName: "ภาษาไทย",
            englishName: "Thai"
        },
        'tl': {
            nativeName: "Filipino",
            englishName: "Filipino"
        },
        'tr': {
            nativeName: "Türkçe",
            englishName: "Turkish"
        },
        'tt-RU': {
            nativeName: "татарча",
            englishName: "Tatar"
        },
        'uk': {
            nativeName: "Українська",
            englishName: "Ukrainian"
        },
        'ur': {
            nativeName: "اردو",
            englishName: "Urdu"
        },
        'uz': {
            nativeName: "O'zbek",
            englishName: "Uzbek"
        },
        'vi': {
            nativeName: "Tiếng Việt",
            englishName: "Vietnamese"
        },
        'vi-VN': {
            nativeName: "Tiếng Việt",
            englishName: "Vietnamese"
        },
        'xh-ZA': {
            nativeName: "isiXhosa",
            englishName: "Xhosa"
        },
        'yi': {
            nativeName: "ייִדיש",
            englishName: "Yiddish"
        },
        'zh': {
            nativeName: "中文",
            englishName: "Chinese"
        },
        'zh-Hans': {
            nativeName: "中文简体",
            englishName: "Chinese Simplified"
        },
        'zh-Hant': {
            nativeName: "中文繁體",
            englishName: "Chinese Traditional"
        }
    };
};

function getNativeNameForLanguageCode(languageCode) {
    return getLanguageList()[languageCode]["nativeName"]
}

function getEnglishNameForLanguageCode(languageCode) {
    return getLanguageList()[languageCode]["englishName"];
}

function LanguageSelect(props) {
    let list = getLanguageList();

    function onChange(event) {
        if (!props.onChange) {
            return;
        }
        const value = event.target.value;
        props.onChange({
            languageCode: value,
            englishName: list[value].englishName,
            nativeName: list[value].nativeName
        });
    }

    return (
        <select onChange={onChange} defaultValue={props.defaultValue} style={{ fontSize: "12pt" }}>
            {
                Object.keys(list)
                    .sort((a, b) => list[a].englishName.localeCompare(list[b].englishName))
                    .map((k, i) => {
                        return (
                            <option key={i} value={k}>
                                {list[k].englishName}
                            </option>
                        );
                    })
            }
        </select>
    );
};

export default LanguageSelect;

export { getEnglishNameForLanguageCode, getNativeNameForLanguageCode }